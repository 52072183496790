import { createApp } from "vue";
import Vuex from "vuex";
import axios from "axios";
import setting from "./setting/index";
import user from "./user/index";
import talant from "./talant/index";
import search from "./search/index";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

createApp(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    setting,
    user,
    talant,
    search
  },
  state: {
    filterType: "",
    filterTypeTxt: "",
    filterStyleTxt: "",
    filterPartnerTxt: "",
    toolTypes: [],
    tools: [],
    styles: [],
    partners: [],
    isLoader: false,
    hint: 'У вас не добавлено ни одного таланта. При поиске ваш профиль не будет отображаться. Добавьте свой талант и вас смогут найти',
    showHint: true,
    loaderState: true
  },
  mutations: {
    SET_LOADER_STATE(state, payload) {
      state.loaderState = payload;
    },
    SET_SHOW_HINT(state, value) {
      state.showHint = value;
    },
    SET_LOADER(state, status) {
      state.isLoader = status;
    },
    SET_TYPETXTFILTER(state, filter) {
      state.filterTypeTxt = filter;
    },
    SET_STYLETXTFILTER(state, filter) {
      state.filterStyleTxt = filter;
    },
    SET_TYPEFILTER(state, filter) {
      state.filterType = filter;
    },
    SET_TOOLTYPES(state, types) {
      state.toolTypes = types;
    },
    SET_TOOLS(state, tools) {
      state.tools = tools;
    },
    SET_STYLES(state, styles) {
      state.styles = styles;
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners;
    },
  },
  getters: {
    isLoader: (state) => state.isLoader,
    filterType: (state) => state.filterType,
    filterTypeTxt: (state) => state.filterTypeTxt,
    filterStyleTxt: (state) => state.filterStyleTxt,
    filterPartnerTxt: (state) => state.filterPartnerTxt,
    toolTypes: (state) => state.toolTypes,
    //  tools: state => state.tools,
    tools: (state) => {
      if (state.filterType !== "" && state.filterTypeTxt !== "")
        return state.tools.filter(
          (item) =>
            item.Type === state.filterType &&
            item.Name.toLowerCase().includes(state.filterTypeTxt.toLowerCase()),
        );
      else if (state.filterType !== "")
        return state.tools.filter((item) => item.Type === state.filterType);
      else if (state.filterTypeTxt !== "")
        return state.tools
          .filter((item) =>
            item.Name.toLowerCase().includes(state.filterTypeTxt.toLowerCase()),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      else return state.tools;
    },
    styles: (state) => {
      if (state.filterStyleTxt !== "")
        return state.styles
          .filter((item) =>
            item.Name.toLowerCase().includes(
              state.filterStyleTxt.toLowerCase(),
            ),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      else return state.styles;
    },
    partners: (state) => {
      if (state.filterPartnerTxt !== "")
        return state.partners
          .filter((item) =>
            item.Name.toLowerCase().includes(
              state.filterPartnerTxt.toLowerCase(),
            ),
          )
          .sort((a, b) => a.Name.localeCompare(b.Name));
      else return state.partners;
    },
  },
  actions: {
    showMessage(context, { type, message }) {
      switch (type) {
        case "success":
          toast.success(message, { autoClose: 3000 });
          break;
        case "error":
          toast.error(message, { autoClose: 3000 });
          break;
      }
    },
    async getToolList(context, data) {
      try {
        const apiUrl = "/api/glossary/getToolList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl + "?" + data, { headers });
        const toolsData = response.data;
        let tools = [];
        tools = toolsData.outIO.ListOfToolList.Tools;
        if (tools !== undefined) {
          // console.log(tools);
          context.commit("SET_TOOLS", tools);
          const uniqueSet = new Set();
          const uniqueArray = [];
          tools.forEach((item) => {
            const key = item.Type + "-" + item.TypeTranslate; // Создаем уникальный ключ
            if (!uniqueSet.has(key)) {
              // Проверяем, есть ли такой ключ в Set
              uniqueSet.add(key); // Добавляем ключ в Set
              const element = {
                Type: item.Type,
                Translate: item.TypeTranslate,
              };
              uniqueArray.push(element); // Добавляем объект в массив уникальных объектов
            }
          });
          context.commit("SET_TOOLTYPES", uniqueArray);
        }
      } catch (error) {
        console.error("Ошибка получения инструментов:", error.message);
      }
    },
    async getStyleList(context, data) {
      try {
        const apiUrl = "/api/glossary/getStyleList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await axios.get(apiUrl + "?" + data, { headers });
        const Data = response.data;
        let styles = [];
        styles = Data.outIO.ListOfStyleList.Styles;
        if (styles !== undefined) {
          context.commit("SET_STYLES", styles);
        }
      } catch (error) {
        console.error("Ошибка получения стилей:", error.message);
      }
    },
    async getSchoolList(context) {
      try {
        const apiUrl = "/api/glossary/getSchoolList"; // Замените на ваш URL API
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        const response = await axios.post(apiUrl, {"languageCode": "RUS" }, { headers });
        const Data = response.data;
        let schools = [];
        schools = Data.outIO.ListOfSchoolList.School;
        if (schools !== undefined) {
          context.commit("SET_PARTNERS", schools);
        }
      } catch (error) {
        console.error("Ошибка получения школ:", error.message);
      }
    },
    async getSessionInfo(context) {
      const apiUrl = "/security-session/user"; // Замените на ваш URL API
      await axios
        .get(apiUrl)
        .then((response) => {
          context.commit("SET_LOGIN", true);
          context.dispatch('getUserInfo');
        })
        .catch((error) => {
          context.commit("SET_LOGIN", false);
        });
    },
  },
  strict: debug,
});
